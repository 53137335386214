import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../context/auth';
import { userType } from '../../global-components/types'
import { useApolloClient, useMutation } from '@apollo/client';
import { RefreshCw } from 'lucide-react'
import './Home.scss';
import Printers from '../../components/printers/Printers'
import PrintQueue from '../../components/printQueue/PrintQueue'
import UploadMacro from '../../components/uploadMacro/UploadMacro'
import BwSymbol from '@/global-components/brand-assets/BwSymbol';
import { User } from '@/global-components/types'
import { Button } from "@/global-components/components/ui/button"

import { Input } from '@/global-components/components/ui/input'

import api from '../../api/bw-api'

import { Switch } from "@/global-components/components/ui/switch"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/global-components/components/ui/tabs"
import { Grip, List } from "lucide-react";

const Home = () =>  {
  const { setLoggedIn } = useAuth();
  const user: User | null = useAuth().user;
  const client = useApolloClient();
  

  

  const logOut = () => {
    setLoggedIn(false);
    api.user.local.removeToken();
    client.clearStore();
    window.location.reload();
  } 

  return (
    <div className='view home'>
      <div className='beta-tag absolute right-2 bottom-0 bg-bw-background-grey/70 text-xs text-bw-green/30 flex items-center gap-1'>
        <Button variant='ghost' size='sm' onClick={() => window.location.reload()}><RefreshCw className='h-4 w-4 font-bold'/></Button>
        {user?.isStaff ? 
         <UploadMacro /> :
         null
        }
        <div className='flex items-center gap-1 ml-2' onClick={logOut}>
          <BwSymbol className='h-4 w-4 opacity-30' small/> 
          <b>FACTORY</b> <i>Beta</i>
        </div>
      </div>
      <Printers />
      <PrintQueue />
    </div>
  )
}

export default Home;