import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import api from '../../api/bw-api'
import { ScheduleItem, PrinterType } from '@/global-components/types'
import { useParams } from "react-router-dom";

import { columnsJobs } from "./PrintQueueColumns"
import { PrintQueueTable } from "./PrintQueueTable"



const PrintQueue = () => {
  const { factoryId } = useParams();
  const [schedule, setSchedule] = useState<ScheduleItem[]>([]);
  const printersQuery = useQuery(api.printers.queries.GET_ALL_PRINTERS_WITH_SCHEDULE, {
    variables: {
      factoryId: factoryId ? factoryId : 1
    },
    pollInterval: 5000,
  });

  const printRequestsQuery = useQuery(api.schedule.queries.GET_ALL_PRINT_REQUESTS, {
    pollInterval: 5000,
  })

  useEffect(() => { 
    if (printersQuery.data) {
      setSchedule([].concat(
        ...printersQuery.data.printersInLocation.map((printer: any) => {
          return printer.schedulemodelSet.filter((scheduleItem: ScheduleItem) => scheduleItem.schedulePosition !== null)
        })
      ))
      
    }
  }, [printersQuery])

  useEffect(() => {
    console.log('print requests')
    console.log(printRequestsQuery)
  }, [printRequestsQuery])
  return (
    <div className='print-queue'>
      <div className="pt-2 pl-4 pr-4 pb-4">
        <PrintQueueTable columns={columnsJobs} data={schedule} />
      </div>
      {/*<PrintRequest />*/}
    </div>
  )
}

export default PrintQueue;