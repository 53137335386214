const dateAndTime = (datetime: string): string => {
  const date = new Date(datetime);
  return date.toLocaleString();
}

const formatDateToDDMMYY = (dateObject: Date) => {
  if (!(dateObject instanceof Date)) {
    dateObject = new Date(dateObject);
  }
  // Date components
  let day = dateObject.getDate().toString().padStart(2, '0');
  let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  let year = dateObject.getFullYear().toString().slice(2);

  // Time components
  let hours = dateObject.getHours().toString().padStart(2, '0');
  let minutes = dateObject.getMinutes().toString().padStart(2, '0');
  let seconds = dateObject.getSeconds().toString().padStart(2, '0');

  return `${day}.${month}.${year} at ${hours}:${minutes}:${seconds}`;
}

const extractFileTypeFromFilename = (filename: string): string => {
  const splitName = filename.split(".");
  return splitName.length > 1 ? splitName[splitName.length - 1].toLowerCase() : '';
}

const extractFileTypeFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  return extractFileTypeFromFilename(urlObject.pathname);
}

const setLocalStorageItem = (key: string, value: string): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};

const getLocalStorageItem = (key: string): string | null => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return null;
  }
};

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

const helpers = {
  dateAndTime,
  formatDateToDDMMYY,
  extractFileTypeFromUrl,
  extractFileTypeFromFilename,
  setLocalStorageItem,
  getLocalStorageItem,
  openInNewTab
}

export default helpers;