import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import Filament from "@/global-components/components/bw/filament/Filament";
import { Button } from "@/global-components/components/ui/button";
import helpers from '@/global-components/components/helpers'
import { ScheduleItem } from '@/global-components/types'


export const columnsJobs: ColumnDef<ScheduleItem>[] = [
  {
    id: 'jobnumber',
    header: '#',
    accessorFn: row => row.job.jobId
  },
  {
    id: "filename",
    header: "Filename",
    size: 20,
    maxSize: 20,
    cell: ({ row }) => {
      const job_name: string = row.original.job.request.file.fileName
      return <span className="font-bold">{job_name}</span>
    }
  },
  {
    accessorKey: "date",
    header: "Issued At",
    accessorFn: row => helpers.formatDateToDDMMYY(row.job.request.issuedAt)
  },
  {
    accessorKey: "issued_by_user_name",
    header: "User",
    accessorFn: row => row.job.request.requestedBy.firstName
  },
  {
    id: "plannedOn",
    accessorKey: "planned_on_printer",
    header: "Target Printer",
    cell: ({row}) => {
      return (
        <span>{row.original.assignedPrinter.printerId}<span className='text-bw-green/30'> ({row.original.assignedPrinter.name})  </span></span>
      )
    }
  },
  {
    id: 'nozzle_size',
    header: "Nozzle",
    accessorFn: row => row.job.nozzleSize + ' mm'
  },
  {
    id: 'filament',
    header: 'Filament',
    cell: ({ row }) => {
      const filamentData = row.original.job.request.filament;
      return (
        <div className='flex items-center gap-1'>
          <Filament active={true} small={true} color={filamentData.colour.hexCode || '#333333'} />
          {filamentData.material.displayName} - {filamentData.colour.displayName}
        </div>
      );
    }
  },
  {
    id: 'openPrintRequest',
    size: 1,
    maxSize: 2,
    header: () => <div className='text-right'>Related Request</div>,
    cell: ({ row }) => {
      const printRequestId: string = row.original.job.request.requestId;
      return (
        <div className='flex justify-end'>
          <Button variant='bwsecondary' disabled size='sm'>
            Show
          </Button>
        </div>
      )
    }
  },
]

export const columnsJobsEmbedded: ColumnDef<ScheduleItem>[] = [
  ...columnsJobs.filter(column => column.id !== 'plannedOn')
]