import { gql } from '@apollo/client';
let GRAPHQL_URI: string = 'https://bw-api-dev.onrender.com/';

console.log('APP BRANCH ENV: ', process.env.REACT_APP_BRANCH )

if (process.env.REACT_APP_BRANCH === 'beta') {
  GRAPHQL_URI = 'https://bw-api-beta.onrender.com/';
} else if (process.env.REACT_APP_BRANCH === 'alex') {
  GRAPHQL_URI = 'http://localhost:8000/';
}

/* GRAPH QL INC. */


/*****************************************************************/
/*                          PRINTERS                             */
/*****************************************************************/

const GET_ALL_PRINTERS_WITH_SCHEDULE = gql`
  query PrintersInLocation($factoryId: ID!) {
      printersInLocation(factoryId: $factoryId) {
        printerId
        name
        displayNumber
        status
        bedTemperature
        nozzleTemperature
        ipAddress
        assigned
        loadedNozzle
        schedulePaused
        technicianReview
        printstatusmodelSet {
        printStatusId
          percentage
          printTimeDone
          printTimeLeft
          completed
          started
          issuedAt
          completedAt
          printTimeLeft
          job {
            fileName
          }    
        }
        activeFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
        inactiveFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
        schedulemodelSet {
          scheduleId
          schedulePosition
          scheduleBumped
          assignedPrinter {
            printerId
            name
          }
          job {
            jobId
            populationId
            priority
            nozzleSize
            completed
            request {
                requestId
                quantity
                jobsCompleted
                interupted
                issuedAt
                filament {
                  filamentId
                  colour {
                    colourId
                    displayName
                    hexCode
                  }
                  material {
                    materialId
                    displayName

                  }
                }
                factory {
                  factoryId
                  name
                }
                requestedBy {
                  userId
                  firstName
                  lastName
                  username
                  email
                }
                file {
                    fileId
                    fileName
                    presignedUrl
                }
            }
            printstatusmodelSet {
              printStatusId
              percentage
              completed
              started
              issuedAt
              completedAt
              printTimeLeft
              job {
                fileName
              }    
            }
          }
          filamentAction {
              actionId
              actionType
              target
              forTechnician
              completed
          }
          nozzleAction {
              actionId
              actionType
              target
              forTechnician
              completed
          }
          printerAction {
              actionId
              actionType
              target
              forTechnician
              completed
          }
        }
      }
  }
`;




const GET_PRINTER = gql`
  query Printer($printerId: ID!) {
    printer(printerId: $printerId) {
      printerId
      name
      displayNumber
      status
      bedTemperature
      nozzleTemperature
      ipAddress
      assigned
      schedulePaused
      technicianReview
      bedClear
      printstatusmodelSet {
        printStatusId
        percentage
        printTimeDone
        printTimeLeft
        completed
        started
        issuedAt
        completedAt
        printTimeLeft
        job {
          fileName
        }    
      }
      factory {
        factoryId
      }
      loadedNozzle
      activeFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
        material {
          materialId
          displayName
          materialName
        }
      }
      inactiveFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
        material {
          materialId
          displayName
          materialName
        }
      }
      schedulemodelSet {
        scheduleId
        schedulePosition
        scheduleBumped
        assignedPrinter {
          printerId
          name
        }
        job {
          jobId
          populationId
          priority
          nozzleSize
          completed
          request {
              requestId
              quantity
              jobsCompleted
              interupted
              issuedAt
              filament {
                filamentId
                colour {
                  colourId
                  displayName
                  hexCode
                }
                material {
                  materialId
                  displayName

                }
              }
              factory {
                factoryId
                name
              }
              requestedBy {
                userId
                firstName
                lastName
                username
                email
              }
              file {
                  fileId
                  fileName
                  presignedUrl
              }
          }
          printstatusmodelSet {
            printStatusId
            percentage
            completed
            started
            issuedAt
            completedAt
            printTimeLeft
            job {
              fileName
            }                 
          }
        }
        filamentAction {
          actionId
          actionType
          target
          forTechnician
          completed
        }
        nozzleAction {
          actionId
          actionType
          target
          forTechnician
          completed
        }
        printerAction {
          actionId
          actionType
          target
          forTechnician
          completed
        }
      }
    }
  }
`

const GET_AVAILABLE_FILAMENTS = gql`
  query Filament {
    filament {
      filamentId
      stock
      colour {
          colourId
          displayName
          hexCode
      }
      material {
          materialId
          displayName
          materialName
          materialCost
      }
    }
  }
`;

const CLEAR_SCHEDULE_FOR_PRINTER = gql`
  mutation ClearScheduleForPrinter($printerId: ID!) {
    clearScheduleForPrinter(printerId: $printerId) {
      success
      error
    }
  }
`;

const REOPTIMISE_SCHEDULE_FOR_PRINTERS = gql`
  mutation ReoptimiseScheduleForPrinter($printerId: ID!) {
    reoptimiseScheduleForPrinter(printerId: $printerId) {
      success
      error
    }
  }
`;

const UPDATE_ACTION = gql`
  mutation UpdateAction($actionId: ID!) {
    updateAction(actionId: $actionId, completed: true) {
      success
      error
    }
  }
`;

const SEND_COMMAND_FOR_PRINTER = gql`
  mutation SendCommandForPrinter($printerId: ID!, $command: String!) {
    sendCommandForPrinter(printerId: $printerId, command: $command) {
      success
      error
    }
  }
`

const CREATE_PRINTER = gql`
  mutation CreatePrinter($name: String!, $uuid: String!, $factoryId: ID!) {
    createPrinter(name: $name, uuid: $uuid, factoryId: $factoryId) {
      success
      error
      createdPrinter {
        printerId
        uuid
        name
        factory {
          factoryId
        }
      }
    }
  }
`;

const DELETE_PRINTER = gql`
  mutation DeletePrinter($printerId: ID!) {
    deletePrinter(printerId: $printerId) {
      success
      error
    }
  }
`

const UPDATE_PRINTER = gql`
  mutation UpdatePrinter($printerId: ID!, $schedulePaused: Boolean, $activeFilamentId: ID, $inactiveFilamentId: ID, $loadedNozzle: Float) {
    updatePrinter(printerId: $printerId, schedulePaused: $schedulePaused, activeFilamentId: $activeFilamentId, inactiveFilamentId: $inactiveFilamentId, loadedNozzle: $loadedNozzle) {
      success
      error
      updatedPrinter {
        printerId
        name
        displayNumber
        status
        bedTemperature
        nozzleTemperature
        ipAddress
        schedulePaused
        technicianReview
        assigned
        factory {
          factoryId
        }
        loadedNozzle
        activeFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
        inactiveFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
      }
    }
  }
`;

const RESET_PRINTER = gql`
  mutation ResetForPrinter($printerId: ID!) {
      resetForPrinter(printerId: $printerId) {
          success
          error
      }
  }
`;

const CANCEL_PRINT_FOR_PRINTER = gql`
  mutation CancelPrintForPrinter($printerId: ID!) {
    cancelPrintForPrinter(printerId: $printerId) {
        success
        error
    }
  }
`;

const PAUSE_PRINT_FOR_PRINTER = gql`
  mutation PausePrintForPrinter($printerId: ID!) {
    pausePrintForPrinter(printerId: $printerId) {
        success
        error
    }
  }
`;

const RESUME_PRINT_FOR_PRINTER = gql`
  mutation ResumePrintForPrinter($printerId: ID!) {
    resumePrintForPrinter(printerId: $printerId) {
        success
        error
    }
  }
`;


const GET_PRINT_STATUS_FOR_FILE = gql`
  query PrintStatusForFile($fileId: ID!) {
    printStatusForFile(fileId: $fileId) {
      printStatusId
      percentage
      printTimeDone
      printTimeLeft
      completed
      started
      issuedAt
      completedAt
    }
  }
`;


const printers = {
  queries: {
    GET_ALL_PRINTERS_WITH_SCHEDULE,
    GET_PRINTER,
    GET_AVAILABLE_FILAMENTS,
    GET_PRINT_STATUS_FOR_FILE
  },
  mutations: {
    CLEAR_SCHEDULE_FOR_PRINTER,
    REOPTIMISE_SCHEDULE_FOR_PRINTERS,
    UPDATE_ACTION,
    CREATE_PRINTER,
    DELETE_PRINTER,
    UPDATE_PRINTER,
    SEND_COMMAND_FOR_PRINTER,
    CANCEL_PRINT_FOR_PRINTER,
    PAUSE_PRINT_FOR_PRINTER,
    RESUME_PRINT_FOR_PRINTER,
    RESET_PRINTER
  }
}


/*****************************************************************/
/*                          SCHEDULE                             */
/*****************************************************************/

const GET_ALL_PRINT_REQUESTS = gql`
  query PrintRequests {
    printRequests {
      requestId
      quantity
      jobsCompleted
      interupted
      issuedAt
    }
  }
`;


const DELETE_PRINT_REQUEST = gql`
  mutation DeletePrintRequest($requestId: ID!) {
    deletePrintRequest(requestId: $requestId) {
      success
      errors
    }
  }
`;


const schedule = {
  queries: {
    GET_ALL_PRINT_REQUESTS
  },
  mutations: {
    DELETE_PRINT_REQUEST
  },
}


/*****************************************************************/
/*                          MACROS                               */
/*****************************************************************/

const GET_ALL_MACROS = gql`
  query AllMacros {
    allMacros {
      macroId
      command
    }
  }
`;

const CREATE_NEW_MACRO = gql`
  mutation CreateMacro($command: String!, $macro: Upload!) {
    createMacro(command: $command, macro: $macro) {
      success
      error
      addedMacro {
        macroId
        command
      }
    }
  }
`;

const SEND_MACRO_TO_PRINTER = gql`
  mutation SendMacroForPrinter($command: String!, $printerId: ID!) {
    sendMacroForPrinter(command: $command, printerId: $printerId) {
      success
      error
    }
  }
`;

const macros = {
  queries: {
    GET_ALL_MACROS,
  },
  mutations: {
    CREATE_NEW_MACRO,
    SEND_MACRO_TO_PRINTER
  }
}

/*****************************************************************
                             USER                               
*****************************************************************/

const ME = gql`
  query Me {
    me {
      lastLogin
      firstName
      lastName
      isStaff
      isActive
      dateJoined
      userId
      email
      username
      id
      pk
      archived
      verified
      secondaryEmail
    }
  }
`;

const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email:$email, password:$password)
    {
        token
        success
        user {
          userId
          username
          isStaff
          firstName
          lastName
        }
    }
  }
`;

const REGISTER = gql`
  mutation Register($email: String!, $password1: String!, $password2: String!, $username: String!, $firstName: String!, $lastName: String!) {
    registerAccount(email:$email, password1:$password1, password2:$password2, username: $username, firstName: $firstName, lastName: $lastName) {
      success
      token
      errors
      user {
        userId
        username
        isStaff
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_MY_ACCOUNT = gql`
  mutation UpdateAccount($firstName: String!, $lastName: String!) {
    updateAccount(firstName: $firstName, lastName: $lastName) {
      success
    }
  }
`;


const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
      success
      errors
    }
  }
`;

const setToken = (token: string) => {
  localStorage.setItem('token', token);
}

const isTokenSet = (): boolean => {
  return localStorage.getItem('token') !== null;
}

const getToken = (): string => {
  return localStorage.getItem('token') || '';
}

const removeToken = () => {
  localStorage.removeItem('token');
}

const user = {
  queries: {
    ME
  },
  mutations: {
    REGISTER,
    UPDATE_MY_ACCOUNT,
    LOGIN,
    VERIFY_TOKEN
  },
  local: {
    setToken,
    isTokenSet,
    getToken,
    removeToken,
  }
}

const api = {
  GRAPHQL_URI,
  user,
  printers,
  schedule,
  macros,
}

export default api;